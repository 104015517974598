const countrySelector = document.getElementById("countrySelector");
if (countrySelector) {
    countrySelector.addEventListener("change", (changeEvent ) => {
        const eventTarget = changeEvent.currentTarget as HTMLSelectElement;
        if (eventTarget) {
            const options = eventTarget.options;
            if (options && options.length && options.selectedIndex) {
                const optionSelected = options[options.selectedIndex];
                const newURL = optionSelected.dataset.url as string;
                window.location.href = `https://${newURL}`;
            }
        }
    });
}
